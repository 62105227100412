<template>
  <div id="tuan">
    <div class="header">
      <van-row gutter="10">
        <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
          />出团详情
        </van-col>
        <van-col
          v-if="tuan.status == 0"
          span="14"
          class="header-title"
          style="text-align: right"
          @click="onEditClick"
        >
          <svg-icon :data_iconName="'edit'" :className="'edit_icon'" />
        </van-col>
      </van-row>
    </div>
    <div class="tuan-body">
      <div class="tuan-body-container">
        <van-row class="tuan-body-header">
          <van-col span="6">
            <van-image class="item-avatar" round :src="tuan.image_url" />
          </van-col>
          <van-col span="15">
            <div class="item-name">
              {{ tuan.list_name }}
            </div>
            <div class="item-sub-text">电话：{{ tuan.guide_mobile }}</div>
            <div class="item-sub-text">
              {{ tuan.guide_role_name }}：{{ tuan.guide }}
            </div>
          </van-col>
          <van-col
            span="3"
            v-if="tuan.status == 0 || tuan.status == 3"
            @click="onDeleteClick"
          >
            <svg-icon :data_iconName="'delete'" :className="'edit_icon'" />
          </van-col>
        </van-row>
        <van-row class="tuan-info">
          <van-col span="10">
            <div class="tuan-start-time text-left">
              {{ tuan.start_date }}
            </div>
            <div class="tuan-start-address text-left">
              {{ tuan.start_addr ? tuan.start_addr : "暂无" }}
            </div>
          </van-col>
          <van-col span="4">
            <div class="need-time">{{ tuan.days }}天</div>
            <!-- <div class="tuan-items" >行程详情</div> -->
          </van-col>
          <van-col span="10">
            <div class="tuan-end-time text-right">{{ tuan.end_date }}</div>
            <div class="tuan-end-address text-right">
              {{ tuan.end_addr ? tuan.end_addr : "暂无" }}
            </div>
          </van-col>
          <van-col span="24">
            <div class="start-date">出发日期：{{ tuan.start_date }}</div>
            <div class="tuan-ren-number">
              团队人数：
              <a class="tuan_link" @click.stop="showTouristDialog = true">
                {{ tuan.tourists_count ? tuan.tourists_count : 0 }} </a
              >人
            </div>
            <div class="tuan-ren-number" v-if="tuan.driver_name">
              司机：{{ tuan.driver_name }}
              {{ tuan.driver_phone || "" }}
            </div>
            <div class="tuan-ren-number">状态：{{ tuan.status_text }}</div>
            <div class="tuan-ren-number" v-if="tuan.status === 3">
              取消原因：{{ tuan.comment }}
            </div>

            <div class="tuan-ren-number">备注：{{ tuan.note }}</div>
          </van-col>
        </van-row>
        <div class="plan-container">
          <div class="plan_name">
            {{ tuan.travel_plan ? tuan.travel_plan.plan_name : "" }}
          </div>
          <div class="trip-step">
            <van-steps
              direction="vertical"
              active-color="#3672f9"
              active-icon="clock"
              :active="tuan.status != 3 && tuan.status != 0 ? step + 1 : -2"
            >
              <van-step
                v-for="(item, index) in tuan.travel_list_nodes"
                :key="index"
              >
                <div v-if="tuan.status != 3 && tuan.status != 0">
                  <div v-if="item.is_checkin" :class="[stateClass[0]]">
                    已完成
                  </div>
                  <div v-if="item.is_checkin">
                    <p style="font-size: 0.3rem; color: #444">
                      {{ item.node_name }}
                    </p>

                    <p style="color: #444">{{ item.node_description }}</p>
                    <p style="color: #444">
                      开始：{{
                        item.start_time ? item.start_time.slice(0, 16) : "--"
                      }}
                    </p>
                    <p style="color: #444">
                      结束：{{
                        item.end_time ? item.end_time.slice(0, 16) : "--"
                      }}
                    </p>
                  </div>

                  <div
                    v-if="!item.is_checkin && step + 1 != index"
                    :class="[stateClass[2]]"
                  >
                    待完成
                  </div>
                  <div v-if="!item.is_checkin && step + 1 != index">
                    <p style="font-size: 0.3rem">{{ item.node_name }}</p>

                    <p>{{ item.node_description }}</p>
                    <p>
                      开始：{{
                        item.start_time ? item.start_time.slice(0, 16) : "--"
                      }}
                    </p>
                    <p>
                      结束：{{
                        item.end_time ? item.end_time.slice(0, 16) : "--"
                      }}
                    </p>
                  </div>
                  <div
                    v-if="!item.is_checkin && step + 1 == index"
                    :class="[stateClass[1]]"
                  >
                    进行中
                  </div>
                  <div v-if="!item.is_checkin && step + 1 == index">
                    <p style="font-size: 0.3rem">{{ item.node_name }}</p>

                    <p>{{ item.node_description }}</p>
                    <p>
                      开始：{{
                        item.start_time ? item.start_time.slice(0, 16) : "--"
                      }}
                    </p>
                    <p>
                      结束：{{
                        item.end_time ? item.end_time.slice(0, 16) : "--"
                      }}
                    </p>
                  </div>
                </div>
                <div v-else-if="tuan.status == 3">
                  <div v-if="item.is_checkin" :class="[stateClass[0]]">
                    已完成
                  </div>
                  <div v-if="item.is_checkin">
                    <p style="font-size: 0.3rem; color: #444">
                      {{ item.node_name }}
                    </p>

                    <p style="color: #444">{{ item.node_description }}</p>
                    <p style="color: #444">
                      开始：{{
                        item.start_time ? item.start_time.slice(0, 16) : "--"
                      }}
                    </p>
                    <p style="color: #444">
                      结束：{{
                        item.end_time ? item.end_time.slice(0, 16) : "--"
                      }}
                    </p>
                  </div>
                  <div v-if="!item.is_checkin" :class="[stateClass[2]]">
                    已取消
                  </div>
                  <div v-if="!item.is_checkin">
                    <p style="font-size: 0.3rem">{{ item.node_name }}</p>

                    <p>{{ item.node_description }}</p>
                    <p>
                      开始：{{
                        item.start_time ? item.start_time.slice(0, 16) : "--"
                      }}
                    </p>
                    <p>
                      结束：{{
                        item.end_time ? item.end_time.slice(0, 16) : "--"
                      }}
                    </p>
                  </div>
                </div>
                <div v-else>
                  <div :class="[stateClass[2]]">待完成</div>
                  <p style="font-size: 0.3rem">{{ item.node_name }}</p>

                  <p>{{ item.node_description }}</p>
                  <p>
                    开始：{{
                      item.start_time ? item.start_time.slice(0, 16) : "--"
                    }}
                  </p>
                  <p>
                    结束：{{
                      item.end_time ? item.end_time.slice(0, 16) : "--"
                    }}
                  </p>
                </div>
              </van-step>
            </van-steps>
            <van-empty
              v-if="tuan.travel_list_nodes.length == 0"
              description="暂无行程线路"
            />
          </div>
        </div>
        <div class="circle-left"></div>
        <div class="circle-right"></div>
      </div>
      <div class="tourist-container">
        <div class="tourist-title">游客名单</div>
        <van-row
          class="tourist-item"
          v-for="(item, index) in tuan.tourists"
          :key="index"
          @click="get_vistor_archive_info(item)"
        >
          <van-col span="14">
            <div class="tourist-text-left">{{ item.name }}</div>
            <div class="tourist-text-left">年龄：{{ item.age }}</div>
          </van-col>
          <van-col span="10">
            <div class="tourist-text-right">{{ item.gender }}</div>
            <div class="tourist-text-right">{{ item.mobile }}</div>
          </van-col>
        </van-row>

        <van-empty
          v-if="tuan.tourists.length == 0"
          description="暂无游客名单"
        />
      </div>
      <van-overlay z-index="100" :show="show" @click="show = false">
        <div class="wrapper" v-if="archive_info.user">
          <h6 class="text-center wrpper-title-large">游客备案</h6>
          <div class="detail">
            <van-row>
              <van-col span="12">
                <div class="detail-title">用户：</div>
              </van-col>
              <van-col span="12">
                <div class="detail-title">{{ archive_info.user.username }}</div>
              </van-col>
            </van-row>
            <van-row>
              <van-col span="12">
                <div class="detail-title">游客电话：</div>
              </van-col>
              <van-col span="12">
                <div class="detail-title">{{ archive_info.user.mobile }}</div>
              </van-col>
            </van-row>
            <van-row>
              <van-col span="12">
                <div class="detail-title">备案电话：</div>
              </van-col>
              <van-col span="12">
                <div class="detail-title">{{ archive_info.mobile }}</div>
              </van-col>
            </van-row>
            <van-row>
              <van-col span="12">
                <div class="detail-title">目的地：</div>
              </van-col>
              <van-col span="12">
                <div class="detail-title">
                  {{ archive_info.destination_address }}
                </div>
              </van-col>
            </van-row>
            <van-row>
              <van-col span="12">
                <div class="detail-title">入住地址：</div>
              </van-col>
              <van-col span="12">
                <div class="detail-title">{{ archive_info.live_address }}</div>
              </van-col>
            </van-row>

            <van-row>
              <van-col span="12"> </van-col>
              <van-col span="12">
                <div class="date" style="text-align: right">
                  {{ archive_info.created_at }}
                </div>
              </van-col>
            </van-row>
          </div>
        </div>
      </van-overlay>
    </div>
    <tourists-dialog
      :tour="tuan"
      :show="showTouristDialog"
      v-on:close="showTouristDialog = false"
    ></tourists-dialog>
  </div>
</template>
<script>
import { Toast, Dialog } from "vant";
import TouristsDialog from "../components/TouristsDialog.vue";
export default {
  name: "tuan",
  components: { TouristsDialog },
  data() {
    return {
      tuan: {
        status: 1,
        tourists: [],
        travel_list_nodes: [],
      },
      active: 1,
      step: -2,
      showTouristDialog: false,
      show: false,
      archive_info: {},
      stateClass: [
        "status-btn-plan",
        "status-btn-ing",
        "status-btn-completed",
        "status-btn-cancel",
      ],
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onEditClick() {
      this.$router.push({
        path: "/tuan/edit",
        query: {
          id: this.$route.query.id,
        },
      });
    },
    async get_travel_list(id) {
      const res = await this.$apis.get_travel_list(id);
      this.tuan = res.data;
      if (res.data.travel_list_nodes.length > 0) {
        var step = -1;
        res.data.travel_list_nodes.forEach((item) => {
          if (item.is_checkin) {
            step = step + 1;
          }
        });
        if (step + 1 < res.data.travel_list_nodes.length) {
          this.step = step;
        }
      }
    },
    onDeleteClick() {
      const _self = this;
      Dialog.confirm({
        // title: "标题",
        message: "确定删除该团吗?",
      })
        .then(() => {
          // on confirm
          _self.delete_travel_list();
        })
        .catch(() => {
          // on cancel
        });
    },
    async delete_travel_list() {
      const id = this.$route.query.id;
      const res = await this.$apis.delete_travel_list(id);
      if (res.status == 200) {
        Toast("删除成功");
        this.$router.go(-1);
      }
    },
    async get_vistor_archive_info(item) {
      const travel_list_id = this.tuan.id;
      const tourist_id = item.id;
      if (!travel_list_id) {
        Toast("没有出团id");
        return false;
      }
      if (!tourist_id) {
        Toast("没有游客id");
        return false;
      }
      const res = await this.$apis.get_vistor_archive_info(
        travel_list_id,
        tourist_id
      );
      if (res.status == 200) {
        if (res.data.user) {
          this.archive_info = res.data;
          this.show = true;
        } else {
          Toast("暂无备案！");
        }
      }
    },
  },
  created() {
    const id = this.$route.query.id ? this.$route.query.id : "1";
    this.get_travel_list(id);
  },
};
</script>

<style scoped>
#tuan {
  height: 100vh;
  padding-top: 10vh;
}
.tuan_link {
  color: #436def;
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
  /* padding-bottom: 1.2rem; */
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.search {
  position: relative;
}
.search-input {
  border-radius: 14.5px;
  /* border-radius: 14.5px; */
  height: 0.6rem;
  width: 100%;
  background: rgba(216, 216, 216, 0.21);
  padding-right: 1rem;
  padding-left: 0.3rem;
  font-size: 0.4rem;
  color: #fff;
  vertical-align: middle;
}
.search-input-icon {
  position: absolute;
  right: 0.4rem;
  top: 0.55rem;
  font-size: 0.4rem;
}
.tuan-body {
  height: 90vh;

  padding: 0.4rem;
  overflow-y: scroll;
  padding-bottom: 6rem;
}
.tuan-body-container {
  background: #ffff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
  position: relative;
  padding: 0.4rem 0.2rem;
  z-index: 10;
}
.tuan-body-header {
  height: 1.4rem;
  border-bottom: 1px dashed #f3f3f3;
  padding: 0 0.2rem;
}
.tuan-body-header .item-avatar {
  overflow: hidden;
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
}
.tuan-body-header .item-name {
  font-size: 16px;
  color: #333;
  font-weight: bold;
}
.tuan-body-header .item-sub-text {
  color: #666;
  font-size: 12px;
}
.tuan-body-header .item-agency-text {
  font-size: 14px;
  color: #666;
}
.lang {
  background: rgba(148, 174, 64, 0.18);
  border-radius: 9px;
  border-radius: 9px;
  text-align: center;
  display: inline-block;
  padding: 2px 5px;
  color: #94ae40;
  font-size: 12px;
  margin-left: 10px;
}
.lang2 {
  background: rgba(75, 183, 78, 0.13);
  border-radius: 9px;
  border-radius: 9px;
  text-align: center;
  display: inline-block;
  padding: 2px 5px;
  color: #4bb74e;
  font-size: 12px;
  margin-left: 10px;
}
.tuan-info {
  margin-top: 0.6rem;
  padding: 0 0.2rem;
}
.tuan-start-time,
.tuan-end-time {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  text-align: center;
}
.tuan-start-address,
.tuan-end-address {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  text-align: center;
}
.tuan-items {
  font-size: 12px;
  color: #436def;
  text-align: center;
}
.need-time {
  font-size: 12px;
  color: #666666;
  text-align: center;
  border-bottom: 1px solid #cccccc;
}
.circle-left {
  position: absolute;
  width: 0.4rem;
  height: 0.4rem;
  background-color: #436ef0;
  z-index: 10;
  top: 1.6rem;
  left: -0.2rem;
  border-radius: 50%;
}
.circle-right {
  position: absolute;
  width: 0.4rem;
  height: 0.4rem;
  background-image: linear-gradient(
    179deg,
    #6c94f6 0%,
    #436ef0 16%,
    #3a5be9 100%
  );
  z-index: 10;
  top: 1.6rem;
  right: -0.2rem;
  border-radius: 50%;
}
.start-date {
  font-size: 12px;
  color: #666666;
  margin-top: 20px;
}
.tuan-ren-number {
  font-size: 12px;
  color: #666666;
}
.plan_name {
  height: 1rem;
  line-height: 1rem;
  border-top: 1px dashed #f3f3f3;
  font-size: 14px;
  color: #333333;
  font-weight: bold;
}
.tourist-title {
  height: 1rem;
  line-height: 1rem;
  border-top: 1px dashed #f3f3f3;
  font-size: 14px;
  color: #333333;
  font-weight: bold;
  padding: 0 0.4rem;
}
.tourist-item {
  height: 1.4rem;
  padding: 0.2rem 0.4rem;
}
.tourist-item:nth-child(even) {
  background: #f4f3f3 !important;
}
.tourist-text-right {
  font-size: 14px;
  height: 0.5rem;
  line-height: 0.5rem;
  color: #666666;
  text-align: right;
}
.tourist-text-left {
  font-size: 14px;
  color: #666666;
  text-align: left;
  height: 0.5rem;
  line-height: 0.5rem;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}

.tourist-container {
  background: #fff;
  /* padding: 0 .2rem; */
}
.plan-container {
  padding: 0 0.2rem;
  margin-top: 0.4rem;
}
.delete_btn {
  text-align: center;
  height: 0.5rem;
  margin-top: 10px;
  background: crimson;
  color: #fff;
  border-radius: 0.25rem;
  font-size: 0.3rem;
}
.edit_icon {
  font-size: 0.6rem;
}

/* 备案弹出框 */
.detail {
  background: #ffffff;
  /* box-shadow: 0 0 6px 0 rgba(182, 179, 179, 0.54); */
  border-radius: 16px;
  border-radius: 16px;
  /* padding: 0.4rem; */
  margin-bottom: 0.3rem;
  width: 100%;
  white-space: normal;
  word-break: normal;
  margin-top: 6px;
}
.detail-title {
  text-align: left;
  font-size: 16px;
  color: #333333;
  font-weight: bold;
}
.date {
  font-size: 14px;
  color: #666666;
  text-align: right;
  margin-top: 0.5rem;
}
.wrapper {
  width: 90vw;
  margin: 0 auto;
  background: #fff;
  border-radius: 20px;
  border-radius: 20px;
  /* height: 40vh; */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0.3rem;
  overflow-y: scroll;
}
.wrpper-title {
  color: #333;
  font-size: 12px;
  font-weight: bold;
}
.wrpper-text {
  color: #666;
  font-size: 12px;
}

.status-btn-completed {
  position: absolute;
  top: 0.1rem;
  right: 0.2rem;
  font-size: 0.25rem;
  border-radius: 12px;
  display: inline-block;
  background: #13c2c212;
  color: #13c2c2;
  padding: 2px 0.2rem;
}
.status-btn-plan {
  position: absolute;
  top: 0.1rem;
  right: 0.2rem;
  font-size: 0.25rem;
  border-radius: 12px;
  display: inline-block;
  color: #4168ee;
  background: #4168ee12;
  padding: 2px 0.2rem;
}
.status-btn-ing {
  position: absolute;
  top: 0.1rem;
  right: 0.2rem;
  font-size: 0.25rem;
  border-radius: 12px;
  display: inline-block;
  color: #fff;
  background: #4168ee;
  padding: 2px 0.2rem;
}
</style>
